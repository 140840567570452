import React from 'react'
import { useTranslation } from 'react-i18next';

function References() {
    const { t } = useTranslation(); 
    return (
        <section className="d-flex flex-column vh-100 justify-content-center align-items-center">
            <p className="sub-heading line-shap line-shap-after mb-15">
                                <span className="line-bg-left title-service-index">{t('home.clients.title')}</span>

                            </p>
            <h3 className='big-title-services' style={{marginBottom:50,textAlign:'center',paddingLeft:30,paddingRight:30,maxWidth:600}}>{t('home.clients.subtitle')}</h3>
            <div className="container brandsCarousel">
                <div className="d-flex carouselTrack">
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/ABSA.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/AIRBNB.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/ANGLSIDE3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/ARSLAN.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/BCD3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/BOEING3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/BOXCOM.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/FAM.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/FOCUSPONIT.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/NSA.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/SKYGLOBAL3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/SUNDOWNS.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/TSU.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/ARSLANN.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/AFREXIMBANK.png" alt="logo" />
                    </div>
                     {/** IMPORTANT: The content below includes clones of the original logos, which are necessary to create a seamless infinite loop effect. */}
                     <div className="brandLogo">
                        <img src="assets/images/clients_logos/ABSA.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/AIRBNB.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/ANGLSIDE3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/ARSLAN.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/BCD3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/BOEING3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/BOXCOM.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/FAM.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/FOCUSPONIT.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/NSA.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/SKYGLOBAL3.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/SUNDOWNS.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/TSU.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/ARSLANN.png" alt="logo" />
                    </div>
                    <div className="brandLogo">
                        <img src="assets/images/clients_logos/AFREXIMBANK.png" alt="logo" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default References