import React, { useEffect } from 'react'
import useScript from './../hooks/useScript';
import Loader from './Loader';
import Header from './Index/Header';
import Menu from './Menu';
import About_Index from './Index/About_Index';
import Services_Index from './Index/Services_Index';
import Paralex_Contact from './Index/Paralex_Contact';
import Vehicule_Index from './Index/Vehicule_Index';
import Footer from './Footer';
import Scroll from './Scroll';
import Cursor from './Cursor';
import { Link } from 'react-router-dom'
import InterventionZone from './Index/InterventionZone';
import { Helmet } from 'react-helmet'
import References from './Index/References';
function Index() {

    /* useEffect(()=>{
        window.location.reload()
    }) */
    // const externalJS1 = process.env.PUBLIC_URL + '/assets/js/jquery-3.1.1.min.js';
    // const externalJS2 = process.env.PUBLIC_URL + '/assets/js/plugins.min.js';
    // const externalJS3 = process.env.PUBLIC_URL + '/assets/js/dsn-grid.min.js';
    // const externalJS4 = process.env.PUBLIC_URL + '/assets/js/custom.js';

    // useEffect(() => {
    //   const script1 = document.createElement("script");
    //   script1.src = externalJS1;
    //   document.body.appendChild(script1);

    //   const script2 = document.createElement("script");
    //   script2.src = externalJS2;
    //   document.body.appendChild(script2);

    //   const script3 = document.createElement("script");
    //   script3.src = externalJS3;
    //   document.body.appendChild(script3);

    //   const script4 = document.createElement("script");
    //   script4.src = externalJS4;
    //   document.body.appendChild(script4);
    // }, [])

    // useScript(process.env.PUBLIC_URL + '/assets/js/jquery-3.1.1.min.js');
    // useScript(process.env.PUBLIC_URL + '/assets/js/plugins.min.js');
    // useScript(process.env.PUBLIC_URL + '/assets/js/dsn-grid.min.js');
    // useScript(process.env.PUBLIC_URL + '/assets/js/custom.js');

    return (
        <>
            <Helmet>
            <title>El ADANI VIP - Executive Protection Company in Morocco</title>
            <meta name="description" content="We ensure the protection of our clients against threats and crimes, mobility and reputation risks. by providing them with experienced protection agents who discreetly adapt to their professional status and their lifestyle." />
            <meta name="keywords" content="Executive protection, bodyguard, VIP, safety, privacy security, security measures, risk, physical safty, financial safety, reputational safty, protection, close protection, security agent, personal risk, security procedures, personal protection, security firm, security company, risk assessement, advanced planning, crimes, threats, Itinerary planning, Morocco, secure transportation, risk control, protection officer"></meta>
            </Helmet>
          
                    <div className="wrapper">
                        <Header></Header>
                        <About_Index></About_Index>
                        <References/>
                        <Services_Index></Services_Index>
                        <Paralex_Contact></Paralex_Contact>
                        <Vehicule_Index></Vehicule_Index>
                        <InterventionZone/>

                    </div>
             

            



        </>
    )
}

export default Index
