import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from "react-i18next"

function About_Index() {
    const { t } = useTranslation();
    return (
        <>
            <section className="about-section p-relative section-margin " data-dsn-title="How We Are"
                data-dsn-animate-multi data-dsn-animate="section">
                <div className="container">
                    <div className="row fill-right-container">
                        <div className="col-lg-6">
                            <div className="box-info pt-60 pb-60">
                                <div className="title-move z-index-1 " data-dsn-grid="move-section"
                                    data-dsn-move="-70" data-dsn-duration="100%" data-dsn-opacity="1.2"
                                    data-dsn-responsive="tablet">
                                    <h2 className="section-title mb-30 dsn-text-shadow text-transform-upper">
                                    {t('home.about.bigtitle')}
                                    </h2>
                                </div>
                                <h6 className="mt-80 pb-30 mb-30 border-bottom title-block">{t('home.about.title')}
                                </h6>
                                <p className="max-w570 dsn-up mb-10 ">
                                {t('home.about.p1')}
                                </p>
                                <p className="max-w570 dsn-up mb-10 ">
                                {t('home.about.p2')}
                                </p>
                                <p className="max-w570 dsn-up mb-30 ">
                                {t('home.about.p3')}
                                </p>



                                <Link to="/About" className="mt-30 dsn-button image-zoom special-Link" data-dsn="parallax">
                                    <span className="dsn-border border-color-reverse-color"></span>
                                    {t('home.about.button')} <span>⟶</span>
                                </Link>
                            </div>
                        </div>

                        <div className="col-lg-6">
                            <div className="background-mask p-20 p-absolute h-100 w-100">
                                     <div className="line line-top"></div>
                                    <div className="line line-bottom"></div>
                                    <div className="line line-left"></div>
                                    <div className="line line-right"></div> 

                                <div className="img-box h-100">
                                    <div className="img-container h-100 before-z-index container-img-overlay" data-dsn-grid="move-up"
                                        data-overlay="3">
                                        <img className="cover-bg-img " src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                            data-dsn-src="assets/images/two_bodyguards2.webp" alt="" />
                                        <div class="overlay-img">
                                        <div class="overlay-text">
                                        <i class='bx bxs-quote-left icon-quote-left'></i> 
                                        {t('home.about.hoverimage.text')} 
                                                 <i class='bx bxs-quote-right icon-quote-right' ></i>
                                                <h3 className="nameQuote">{t('home.about.hoverimage.nameceo')}</h3>
                                                </div>
                                           </div>
                                        </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </section>
        </>
    )
}

export default About_Index
